<template>
  <div v-if="items">
    <draggable
      v-model="items"
      :draggable="'.itemDraggable'"
      style="width:100%;"
      v-bind="dragOptions"
      @start="onSortStart"
      @end="onSortItems(items)"
    >
    <div style="cursor: pointer;" 
      v-for="item in items" 
      :key="item.ID + item.Name" 
      v-bind:class="'itemDraggable'"
    >
        <v-toolbar
          elevation="1"
          :style="{ 'padding-left' : `${item.Level * 20}px`}"
        >
          <!-- <v-app-bar-nav-icon>mdi-lead-pencil</v-app-bar-nav-icon> -->
          <v-icon small style="margin-right: 10px;">mdi-cursor-move</v-icon>
          <v-checkbox
            v-if="workspaces && workspaces.length > 1"
            v-model="selectedItems"
            hidedetails
            style="margin-top:25px"
            multiple
            :value="item.ID"
          />
          <v-toolbar-title>
            <a @click="onEdit(item.ID)" style="cursor: pointer;">
              {{ getTranslation(item.Name, locale) }}
              {{ item.CategoryID ? `[ ${getCategoryName(item.CategoryID)} ]` : ''}}
            </a>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </div>
    </draggable>
    <v-layout wrap
      v-if="workspaces && workspaces.length > 1"
      style="padding: 20px;"
    >
      <v-flex xs1 style="padding: 0 5px 0 0;">
        <v-btn
          v-if="workspaces && workspaces.length > 1"
          @click="handleSelectAll"
          block
        >
          <v-icon>mdi-checkbox-multiple-outline</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs1 style="padding: 0 5px;">
        <v-btn
          v-if="selectedItems.length > 0"
          @click="selectedItems = []"
          block
        >
          <v-icon>mdi-checkbox-blank-outline</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs1 style="padding: 0 5px;">
        <delete-item
          v-if="selectedItems.length > 0"
          :id="selectedItems"
          :onDeleteItem="onDeleteMulti"
          label=""
          :locale="locale"
          :onlyIcon="true"
          :block="true"
          :isInList="true"
        />
      </v-flex>
      <v-flex xs4 style="padding: 0 10px;">
        <v-autocomplete
          v-if="selectedItems.length > 0"
          :items="workspaces"
          v-model="selectedWorkspace"
          item-value="ID"
          item-text="Name"
          solo
          hideDetails
          dense
        />
      </v-flex>
      <v-flex xs5>
        <v-btn
          v-if="selectedItems.length > 0 && selectedWorkspace"
          @click="handleMove"
          color="primary"
        >
          <v-icon left>mdi-content-copy</v-icon>
          copiar
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import draggable from 'vuedraggable'
  import utils from '@/services/utils'
  import api from '@/services/api'
  import DeleteItem from '@/components/DeleteItem'
  export default {
    name: 'draggable-table',
    components: {
      draggable,
      DeleteItem,
    },
    props: {
      data: {
        type: Array,
        required: true
      },
      sourceWorkspaceID: {
        type: String,
        required: true,
      },
      onSortItems: {
        type: Function,
        required: false,
        default: null
      },
      onSortStart: {
        type: Function,
        required: false,
        default: null
      },
      onEdit: {
        type: Function,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: false,
        default: null
      },
      onCopyItems: {
        type: Function,
        required: true,
      },
      onDeleteMulti: {
        type: Function,
        requried: true,
      },
      categories: {
        type: Array,
        requried: true,
      },
    },
    data () {
      return {
        items: null,
        workspaces: null,
        selectedItems: [],
        selectedWorkspace: null,
      }
    },
    computed: {        
      ...mapState('app', ['locale']),
      dragOptionsDisabled() {
        return {
          animation: 200,
          group: "description",
          disabled: true,
          ghostClass: "ghost"
        };
      },
      dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    watch:{
      data (v) {
        this.items = v
      },
      
    },
    mounted () {
      this.items = this.data
      this.handleGetWorkspaces()
    },
    methods: {
      getCategoryName (v) {
        if (!v || !this.categories) return
        return this.categories.filter(x => x.ID===v).map(item => item.Name).shift()
      },
      handleGetWorkspaces() {
        api.getAllWithoutLimit ('dtouch', `v1/internal/users/${utils.getUser()}/workspaces`)
          .then(response => {
            this.workspaces = response.map(item => {
              return {
                ID: item.ID,
                Name: item.Name,
              }
            })
          })
      },
      getTranslation (v, locale) {
        return utils.getTranslation(v, locale)
      },
      handleSelectAll () {
        this.selectedItems = this.items.map(item => item.ID)
      },
      handleMove () {
        const body = {
          items: this.selectedItems,
          workspaceID: this.selectedWorkspace,
          sourceWorkspaceID: this.sourceWorkspaceID
        }
        this.onCopyItems(body)
      },
    },
  }
</script>

